import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import ClearOutlined from '@material-ui/icons/ClearOutlined';
import AddOutlined from '@material-ui/icons/AddOutlined'
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined'
import PlaceOutlined from '@material-ui/icons/PlaceOutlined'
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { connect } from 'react-redux';
import { map as _map, findIndex as _findIndex, get as _get, isEmpty as _isEmpty, find as _find, sortBy as _sortBy, filter as _filter } from 'lodash';
import genericGetData from "../../Redux/Actions/genericGetData";
import genericPostData from "../../Redux/Actions/genericPostData";
import ShoppingBasketOutlined from "@material-ui/icons/ShoppingBasketOutlined";
import Scrollbar from "react-scrollbars-custom";
import { Container, Row, Col } from 'reactstrap'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import StarRatingComponent from 'react-star-rating-component';
import { ListGroup, ListGroupItem } from 'reactstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProductTabs from './ProductTabs';
import {
    FacebookIcon,
} from "react-share";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import { isMobile, isTablet } from 'react-device-detect';
import { Loader } from '../../Global/UIComponents/LoaderHoc';
// import AliceCarousel from 'react-alice-carousel'
// import 'react-alice-carousel/lib/alice-carousel.css'
import Titos from '../../assets/images/Titos.jpg'
import Carousel from 'react-multi-carousel';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-multi-carousel/lib/styles.css';
import { commonActionCreater } from "../../Redux/Actions/commonAction";
import RFReactSelect from '../../Global/FormCompoents/react-select-wrapper';
import { Form, Field } from 'react-final-form';
import { cleanEntityData, deliveryMethods } from '../../Global/helper/commonUtil';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TopCategoryComponent from '../../Components/StoreComponents/TopCategoryComponent';
import { Rate, Progress } from 'antd';

import { ProductView, PageView, ProductAddedtoCart } from '../../Global/helper/react-ga';
import showMessage from '../../Redux/Actions/toastAction';

import 'antd/dist/antd.css';
import { ThreeSixty } from '@material-ui/icons';


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11f issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },

});

const sizeDropDown = [
    {
        "name": "750ml",
        "value": "750ml"
    },
    {
        "name": "375ml",
        "value": "375ml"
    },
    {
        "name": "200ml",
        "value": "200ml"
    },
    {
        "name": "50ml",
        "value": "50ml"
    }
]

const options = _map(sizeDropDown, s => cleanEntityData({
    value: _get(s, 'value'),
    label: _get(s, 'name')
}));

const quantityDropDown = [
    {
        "name": 1,
        "value": 1
    },
    {
        "name": 2,
        "value": 2
    },
    {
        "name": 3,
        "value": 3
    },
    {
        "name": 4,
        "value": 4
    },
    {
        "name": 5,
        "value": 5
    }
]

const quantityOptions = _map(quantityDropDown, s => cleanEntityData({
    value: _get(s, 'value'),
    label: _get(s, 'name')
}));

class ProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultQuantity: 1,
            productPrice: "",
            showReviews: false,
            slideIndex: 0,
            isLoading: true,
            size: "",
            price: "",
            productID: "",
            speedDropdown: [],
            selectedSpeed: "",
            // responsive: {
            //     0: { items: 3 },

            // }
            responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 1200 },
                    items: 5
                },
                desktop: {
                    breakpoint: { max: 1199, min: 768 },
                    items: 4
                },
                tablet: {
                    breakpoint: { max: 767, min: 464 },
                    items: 3,
                    slidesToSlide: 3
                },
                mobile: {
                    breakpoint: { max: 575, min: 320 },
                    items: 2,
                    slidesToSlide: 2
                }
            }
        }

    }

    // componentDidUpdate(prevProps) {
    //     console.log('check', prevProps, this.props.location.pathname);
    //   if (
    //     this.props.location.pathname !== prevProps.location.pathname
    //   ) {
    //       console.log('check1', this.props.location.pathname);
    //     window.scrollTo(0, 0);
    //   }
    //   window.scrollTo(0, 0);
    // }

    componentDidMount() {
        // window.scrollTo(0, 0);

        console.log('checking window scroll');
        const productID = this.props.match.params.productID;
        const loc_id = localStorage.getItem('vendor_location_id');
        const dineinTime = localStorage.getItem('dineinTime');
        const zipcode = localStorage.getItem('zipcode');
        const couriertype = localStorage.getItem('couriertype')
        genericGetData({
            dispatch: this.props.dispatch,
            url: `/connect/index/product?prodid=${productID}&store_id=1&loc_id=${loc_id}&dineinTime=${dineinTime}&zipCode=${zipcode}&courier_type=${couriertype}`,
            constants: {
                init: "PRODUCT_DETAILS_LIST_INIT",
                success: "PRODUCT_DETAILS_LIST_SUCCESS",
                error: "PRODUCT_DETAILS_LIST_ERROR"
            },
            identifier: "PRODUCT_DETAILS_LIST",
            successCb: this.productDetailsFetchSuccess,
            errorCb: this.productDetailsFetchError,
            dontShowMessage: true
        })
        const categoryType = this.props.match.params.categoryType;

        let index = _findIndex(this.props.categoriesList, { 'category_name': categoryType })
        if (index == -1) {
            index = 0
        }
        this.setState({ tabValue: index })
        this.FooterAddButtonFunction();

        // this.setState({ size: _get(this.props, "bottleSize", null) })
        // let productIndex = _findIndex(_get(this.props, 'productDetailsData.child', []), { "bottle_size": _get(this.props, "bottleSize", null) })
        // let price = _get(this.props, `productDetailsData.child[${productIndex ? productIndex : 0}].price`, null);
        // this.setState({ price })
    }

    FooterAddButtonFunction = () => {
        let data = {
            product_id: this.props.match.params.productID,
            qty: this.state.defaultQuantity,
            api_token: localStorage.getItem("Token"),
            cart_id: localStorage.getItem("cart_id")
        };
        this.props.dispatch(commonActionCreater(data, "PRODUCT_DETAILS_FOOTER"));
    }

    productDetailsFetchSuccess = (data) => {
        const productId = this.props.match.params.productID;
        const payload = cleanEntityData({
            productId,
            name: _get(data, 'name'),
            price: _get(data, 'price') ? Number(_get(data, 'price')) : undefined
        })
        ProductView(payload);
        PageView();
        const vendorList = [];
        let vendorListData = [];
        const respVendorList = _get(data, 'vendor_list', {})
        Object.keys(respVendorList).forEach(v => {
            const keyValue = respVendorList[v]
            vendorList.push({ value: keyValue[0].vendor_name, displayText: keyValue[0].vendor_name })
            _map(keyValue, kv => {
                vendorListData.push({ ...kv, 'loc_id': v })
            })
        })
        // console.log(vendorList, vendorListData, 'vendor');
        vendorListData = _sortBy(vendorListData, ['price']);
        // console.log(vendorListData, 'vendorlistdata');
        const selectedSizeProdId = _get(data, "child[0].id", null);
        const selectedVendor = _find(vendorListData, {prod_id: selectedSizeProdId});

        let selectedProd = _find(_get(data, 'child'), { id: this.props.selectedBottleProductId });
        if (_isEmpty(selectedProd)) {
            selectedProd = _get(data, "child[0]", null);
        }
        const selectedProdId = _get(selectedProd, 'id');
        // console.log(selectedProd, 'check selected prod');
        const selectedBottleSize = _get(selectedProd, 'bottle_size', null);
        // const selectedPrice = _get(selectedProd, 'price', null);

        const speedDropdown = _map(_get(selectedProd, 'speed_id', []), s => {
            return {
                displayText: _get(s, 'Type'),
                value: _get(s, 'Type'),
                loc_id: _get(s, 'Loc_id'),
                price: _get(s, 'Price')
            }
        });
        const selectedSpeed = _get(speedDropdown, '0.value', '');
        const selectedPrice = _get(speedDropdown, '0.price', '');

        this.setState({ isLoading: false, price: selectedPrice, size: selectedBottleSize, productID: selectedProdId, maxPrice: _get(data, "child[0].maximumprice", null), vendorList, vendorListData, selectedVendor: _get(selectedVendor, 'vendor_name'), selectedPrice: _get(selectedVendor, 'price'), speedDropdown, selectedSpeed })
    }

    handleTabChange = (index) => {
        this.setState({ tabValue: index });
        let categoryName = _get(this.props, `categoriesList[${index}].category_name`, null)
        this.props.history.push(`/category/${categoryName}`)
    };

    handleQuantity = (value) => {
        let quantity = this.state.defaultQuantity;
        if (value == "add") {
            quantity += 1
        }
        else if (value == "less" && this.state.defaultQuantity > 1) {
            quantity -= 1
        }
        let productPrice = (quantity * this.props.productDetailsData.price).toFixed(2)
        this.setState({ defaultQuantity: quantity, productPrice }, () => {
            let data = {
                product_id: this.props.match.params.productID,
                qty: this.state.defaultQuantity,
                api_token: localStorage.getItem("Token"),
                cart_id: localStorage.getItem("cart_id")
            };
            this.props.dispatch(commonActionCreater(data, "PRODUCT_DETAILS_FOOTER"));
        })

    }

    handleReviews = (reviewsList) => {
        if (!_isEmpty(reviewsList)) {
            this.setState({ showReviews: !this.state.showReviews })
        }
    }

    handleAddToCart = () => {
        const vendorDetails = _find(this.state.vendorListData, { prod_id: this.state.productID, vendor_name: this.state.selectedVendor })
        const selectedSpeedData = _find(this.state.speedDropdown, { value: this.state.selectedSpeed });
        
        let reqObj = {
            product_id: this.state.productID,
            qty: this.state.defaultQuantity,
            api_token: localStorage.getItem("Token"),
            cart_id: localStorage.getItem("cart_id"),
            zipcode: localStorage.getItem("zipcode"),
            // loc_id : localStorage.getItem('retailer'),
            // loc_id: _get(vendorDetails, 'loc_id'),
            loc_id: _get(selectedSpeedData, 'loc_id'),
            wallet: 0,
            speed_id: this.state.selectedSpeed
        };
        this.setState({ addToCartLoading: true });
        genericPostData({
            dispatch: this.props.dispatch,
            reqObj: reqObj,
            url: `/api/cart/addtocart`,
            constants: {
                init: "ADD_TO_CART_INIT",
                success: "ADD_TO_CART_SUCCESS",
                error: "ADD_TO_CART_ERROR"
            },
            identifier: "ADD_TO_CART",
            successCb: this.addToCartSuccess,
            errorCb: this.addToCartFailure,
            dontShowMessage: true,
        })
    }

    thumbItem = (item, i) => (
        <span key={item} onClick={() => this.Carousel.slideTo(i)}>
            *{' '}
        </span>
    )

    reactGAAddToCartEvent = () => {
        const p = this.props.productDetailsData;
        const productId = this.props.match.params.productID;
        const payload = cleanEntityData({
            productId,
            name: _get(p, 'name'),
            price: _get(p, 'price') ? Number(_get(p, 'price')) : undefined,
            quantity: this.state.defaultQuantity,
        });
        ProductAddedtoCart(payload);
    };

    addToCartSuccess = (cartData) => {
        console.log("==addToCartSuccess=====data=============", cartData);

        const data = cartData[0];
        if (data.code == 1) {
            console.log("==addToCartSuccess=====in data.code=============", data.total_products_in_cart);
            this.reactGAAddToCartEvent();
            this.setState({ addToCartLoading: false })
            localStorage.setItem("cart_id", data.cart_id);
            localStorage.setItem("total_products_in_cart", data.total_products_in_cart);
            // checking guest login
            if (_isEmpty(_get(this.props.userSignInInfo, '[0].result.api_token', ''))) {
                this.props.history.push('/guest/register')

            } else {
                localStorage.setItem('walletOrder', false);
                this.props.history.push('/cart');
            };
        }
        else {
            console.log("==addToCartSuccess=====in else=============", data);
            this.setState({ addToCartLoading: false })
            this.props.dispatch(showMessage({ text: data.message, isSuccess: false }));
            // alert(data.message);
        }

    }

    addToCartFailure = () => {
        this.setState({ addToCartLoading: false })
    }

    handleIndicator = (event) => {
        this.setState({ slideIndex: event });
    }

    handleBackAction = () => {
        let categoryName = _get(this.props, `categoriesList[${this.state.tabValue}].category_name`, null)
        let categoryID = _get(this.props, `categoriesList[${this.state.tabValue}].category_id`, null)
        this.props.history.push(`/store/category/${categoryName}/${categoryID}`)
    }

    onSubmit = async values => {
        console.log("valuessssssssssssssssssssss", values)
    }

    onSubmitQuantity = () => {

    }

    handleChangeSize = (e) => {

        let size = e.target.value;

        this.setState({ size });
        let productObj = _find(this.props.productDetailsData.child, { 'bottle_size': size });
        let selectedVendor = _find(this.state.vendorListData, { prod_id: _get(productObj, "id", "") });
        // let selectedVendor = _filter(this.state.vendorListData, {prod_id: _get(productObj, "id", "")});
        // console.log(selectedVendor, 'selected vendor');
        // selectedVendor = { prod_id: '26258', vendor_name: 'National Orchid', price: '180.00', qty_avail: '100000', loc_id: '611'}
        // this.setState({ price: _get(selectedVendor, "price", null), productID: _get(productObj, "id", ""), maxPrice: _get(productObj, 'maximumprice', null), selectedVendor: _get(selectedVendor, 'vendor_name'), selectedPrice: _get(selectedVendor, 'price') })

        const speedDropdown = _map(_get(productObj, 'speed_id', []), s => {
            return {
                displayText: _get(s, 'Type'),
                value: _get(s, 'Type'),
                loc_id: _get(s, 'Loc_id'),
                price: _get(s, 'Price')
            }
        });
        const selectedSpeed = _get(speedDropdown, '0.value', '');
        const selectedPrice = _get(speedDropdown, '0.price', '');
        // this.setState({ price: _get(productObj, "price", null), productID: _get(productObj, "id", ""), maxPrice: _get(productObj, 'maximumprice', null), selectedVendor: _get(selectedVendor, 'vendor_name'), selectedPrice: _get(productObj, 'price') });

        this.setState({ price: selectedPrice, productID: _get(productObj, "id", ""), maxPrice: _get(productObj, 'maximumprice', null), selectedVendor: _get(selectedVendor, 'vendor_name'), selectedPrice: _get(productObj, 'price'), speedDropdown, selectedSpeed });
    }

    handleChangeQuantity = (e) => {
        let qty = e.target.value;
        this.setState({ defaultQuantity: qty });
    }

    handleChangeVendor = (e) => {
        let vendor = e.target.value;
        let selectedVendor = _find(this.state.vendorListData, { prod_id: this.state.productID, vendor_name: vendor });
        this.setState({ selectedVendor: vendor, price: _get(selectedVendor, 'price') });
    }

    handleChangeSpeed = (e) => {
        let speed = e.target.value;
        const selectedSpeed = _find(this.state.speedDropdown, {value: speed});

        this.setState({ selectedSpeed: speed, price: _get(selectedSpeed, 'price') });
    }

    renderContent = (quantityArr, sizeOptionsArr, productDetailsData, Ingredients, descriptionContent, vendorArr, speedArr) => {
        // let reviews = _get(productDetailsData, "reviews", []);
        // let ratingSummary = _get(productDetailsData, "rating_summary", 0);
        let reviews = _get(productDetailsData, "reviews", null)
        let ratingSummary = 4;
        let booziness = 20;
        let adventurousness = 30;
        let sweetness = 40;
        let difficulty = 50;
        let skillTime = 3;
        console.log(reviews, ratingSummary, 'length');
        let commonContent = <>
            {/* <div className="scrollerwrapper" > */}
            {/* <Row className="mb-5 flex-column flex-md-row justify-content-md-between no-gutters" > */}
            {/* <Col xs={'auto'} className="prostarRatings order-md-2 ">
                        <div className="reviewsBox d-flex align-items-center mb-3">
                            <StarRatingComponent value={averageRating} starCount={5} editing={false} />
                            <span style={{ fontSize: '1.2rem' }} >{this.props.productDetailsData.review_count}</span>
                            {!this.state.showReviews ? <ExpandMoreOutlined style={{ cursor: "pointer" }} onClick={() => this.handleReviews(reviewsList)} /> : <ClearOutlined style={{ cursor: "pointer" }} onClick={() => this.handleReviews(reviewsList)} />}
                        </div>
                        {this.state.showReviews ?
                            <Scrollbar className="productReviewHolder">
                                <div className="d-flex flex-column">{reviewsList}</div>
                            </Scrollbar>
                            : ""}
                              {/* <div className="mb-5">{_get(productDetailsData, "description", null)}</div>*}
                    </Col> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <Rate allowHalf defaultValue={2.5}/>
                </div> */}

            <Col className="order-md-1" >
                <div className="proName  mb-3 d-flex align-items-center" >
                    {_get(productDetailsData, "name", null)}

                </div>
                {/* <div style={{ fontSize: '2.5rem', lineHeight: '3rem', fontFamily: 'sans-serif'}} className="mb-4 mt-4"> {_get(productDetailsData, 'description')}</div> */}
                {reviews.length > 0 && <div style={{ marginBottom: 20 }}><span ><Rate disabled style={{ color: "#f63", fontSize: 18 }} value={ratingSummary} allowHalf /></span><a href="#reviews"><span className="reviewCount">{`  ${_get(productDetailsData, "review_count", "0 reviews")}`}</span></a></div>}

                <div className="customSelectSize mb-4">
                    <FormControl style={{ display: 'flex' }}>
                        <InputLabel id="demo-simple-select-label">SELECT SIZE</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.size}
                            onChange={(e) => this.handleChangeSize(e)}
                        >
                            {sizeOptionsArr}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Card>
                        <CardBody className="p-4">
                            <CardTitle className="textBlack">
                                {/* <div className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between no-gutters align-items-lg-center mb-lg-4">
                                    <div className="d-flex flex-row">
                                    <div style={{ marginRight: 5}}>$</div>
                                    { this.state.maxPrice && (this.state.maxPrice !== this.state.price)?  <div className="proPrice mb-4 mb-lg-0"  style={{ textDecoration: "line-through red", marginRight: 10}}>{this.state.maxPrice}<span></span></div> : null }
                                    <div className="proPrice mb-4 mb-lg-0" >{this.state.price}</div>
                                    </div>
                                </div> */}

                                <Row className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between no-gutters align-items-lg-center ">
                                    <div className="col-6 col-lg-4  mb-4 mb-lg-0 customSelectSize produtQty">
                                        <FormControl style={{ display: 'flex' }}>
                                            <InputLabel id="demo-simple-select-label">SPEED</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="mt-4"
                                                value={this.state.selectedSpeed}
                                                onChange={(e) => this.handleChangeSpeed(e)}
                                            >
                                                {speedArr}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-6 col-lg-4  mb-4 mb-lg-0 customSelectSize produtQty">
                                        <FormControl style={{ display: 'flex' }}>
                                            <InputLabel id="demo-simple-select-label">QUANTITY</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="mt-4"
                                                value={this.state.defaultQuantity}
                                                onChange={(e) => this.handleChangeQuantity(e)}
                                            >
                                                {quantityArr}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>$ {this.state.price}</div>

                                    {/* <div className="col-auto ">
                                        <Button 
                                        disabled = { _get(productDetailsData, "stock_status", "") == "in stock" ? false : true }
                                        onClick={() => this.handleAddToCart()} 
                                        variant="contained" 
                                        className="bottomActionbutton order-1 col-12 col-md-auto order-md-2 cartActionBtn" 
                                        type="submit">
                                            {this.state.addToCartLoading ? <CircularProgress /> : <> 
                                                <ShoppingCartOutlinedIcon className="iconSize mr-2"></ShoppingCartOutlinedIcon> { _get(productDetailsData, "stock_status", "") == "in stock" ? "ADD TO CART" : "OUT OF STOCK" }</>
                                            }
                                        </Button>
                                    </div> */}

                                </Row>



                            </CardTitle>
                        </CardBody>
                    </Card>
                </div>
                <Row className='d-flex justify-content-end'>
                    <div className="col-auto " style={{}}>
                        <Button
                            disabled={_get(productDetailsData, "stock_status", "") == "in stock" ? false : true}
                            onClick={() => this.handleAddToCart()}
                            variant="contained"
                            className="bottomActionbutton order-1 col-12 col-md-auto order-md-2 cartActionBtn"
                            type="submit">
                            {this.state.addToCartLoading ? <CircularProgress /> : <>
                                <ShoppingCartOutlinedIcon className="iconSize mr-2"></ShoppingCartOutlinedIcon> {_get(productDetailsData, "stock_status", "") == "in stock" ? "ADD TO CART" : "OUT OF STOCK"}</>
                            }
                        </Button>
                    </div>
                </Row>


                <div >
                    <h1 style={{ marginTop: 10, fontSize: '2rem', lineHeight: '2rem', fontFamily: 'sans-serif' }}>About the Product:</h1>
                    <div style={{ lineHeight: '2rem', fontFamily: 'sans-serif' }} className="mb-4 mt-4"> {_get(productDetailsData, 'description')}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', lineHeight: '2rem', fontFamily: 'sans-serif' }}>
                        <div>Brand Name: </div>
                        <div>  {_get(productDetailsData, "child[0].brand_name", 'N/A')}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>Product Type: </div>
                        <div>{_get(productDetailsData, "child[0].product_type", 'N/A')}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>Product Category: </div>
                        <div>{_get(productDetailsData, "child[0].product_category", 'N/A')}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>Country of Origin: </div>
                        <div>{_get(productDetailsData, "child[0].country_of_manufacture", 'N/A')}</div>
                    </div>
                </div>
                {_get(productDetailsData, 'taste_scale') && <Row style={{ marginTop: 20 }}>
                    <Col xs={12} md={12} className="pr-md-5">
                        <h1>Taste Scale</h1>
                        <Progress percent={booziness} strokeColor="#f63" showInfo={false} />
                        <div style={{ fontSize: "12px", fontWeight: "600", letterSpacing: "1.2px", marginBottom: 10 }}><span style={{ color: "#f63" }}>LIGHT</span><span style={{ float: "right" }}>BOOZY</span></div>
                        <Progress percent={adventurousness} strokeColor="#f63" showInfo={false} />
                        <div style={{ fontSize: "12px", fontWeight: "600", letterSpacing: "1.2px", marginBottom: 10 }}><span style={{ color: "#f63" }}>CLASSIC</span><span style={{ float: "right" }}>ADVENTUROUS</span></div>
                        <Progress percent={sweetness} strokeColor="#f63" showInfo={false} />
                        <div style={{ fontSize: "12px", fontWeight: "600", letterSpacing: "1.2px", marginBottom: 10 }}><span style={{ color: "#f63" }}>DRY</span><span style={{ float: "right" }}>SWEET</span></div>
                    </Col>
                </Row>}
                {_get(productDetailsData, 'skill_scale') && <Row style={{ marginTop: 20 }}>
                    <Col xs={12} md={12} className="pr-md-5">
                        <h1 className="mt-5 mt-md-0">Skill Level</h1>
                        <Progress percent={difficulty} strokeColor="#f63" showInfo={false} />
                        <div style={{ fontSize: "12px", fontWeight: "600", marginBottom: "10px", letterSpacing: "1.2px", marginBottom: 10 }}><span style={{ color: "#f63" }}>EASY</span><span style={{ float: "right" }}>ADVANCED</span></div>
                        {_get(productDetailsData, "time", null) &&
                            <>
                                <h1 className="mt-5">Time</h1>
                                <div style={{ letterSpacing: "1.2px" }}>{_get(productDetailsData, "time", 3)} Minutes</div></>}

                    </Col>
                </Row>}
            </Col>

            <div className="d-flex flex-wrap justify-content-between justify-content-md-start flex-md-row pt-30" >

            </div>
        </>
        return <div style={{ overflow: 'hidden', width: '100%' }}>{commonContent}</div>
    }

    render() {
        const { isLoading } = this.state;
        // console.log("product details", this.props.productDetailsData)

        let Ingredients = []
        const { productDetailsData } = this.props;
        let sizeList = _get(productDetailsData, "child", null);
        let sizeOptionsArr = [];
        !_isEmpty(sizeList) && sizeList.map((size, index) => {
            if (_get(size, 'bottle_size') != '0') {
                sizeOptionsArr.push(
                    <MenuItem value={_get(size, "bottle_size", null)}>{_get(size, "bottle_size", null) + "ML"}</MenuItem>

                )
            }

        })
        let quantityArr = [];
        let qtyList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
        !_isEmpty(qtyList) && qtyList.map((qty, index) => {
            quantityArr.push(
                <MenuItem value={qty}>{qty}</MenuItem>

            )
        })
        let vendorArr = [];
        !_isEmpty(this.state.vendorList) && this.state.vendorList.map(v => {
            vendorArr.push(
                <MenuItem value={_get(v, 'value')}>{_get(v, 'displayText')}</MenuItem>

            )
        });
        let speedArr = [];
        !_isEmpty(this.state.speedDropdown) && this.state.speedDropdown.map(v => {
            speedArr.push(
                <MenuItem value={_get(v, 'value')}>{deliveryMethods[`${_get(v, 'displayText')}`]}</MenuItem>

            )
        });
        let productImages = [];
        !_isEmpty(productDetailsData.images) && productDetailsData.images.map((image, index) => {
            productImages.push(
                <div className=" d-flex justify-content-between flex-column align-items-center h-100 ">
                    <img src={image} className="img-responsive" />
                </div>
            )
        })

        let decriptionArray = [];
        let descriptionSplit = _get(productDetailsData, "description", "").split('\r\n');
        !_isEmpty(descriptionSplit) && descriptionSplit.map((review, index) => {
            if (review !== null && review !== undefined && review !== "") {
                decriptionArray.push(review);
            }
        })

        let descriptionContent = decriptionArray.map((data, index) => (<React.Fragment>
            <li>{data}</li>
        </React.Fragment>));


        return (

            <React.Fragment >
                <div className="page-content-container">
                    <Container fluid={true} className="productDetails" >
                        {isLoading ? <Loader /> :
                            <>
                                <Row className="no-gutters justify-content-lg-between secMinHeight">
                                    <Col xs={12} md={5} xl={6}>

                                        <div className="productImgSection proDetailSec">
                                            <img src={_get(productDetailsData, 'child.0.image')} className={_get(productDetailsData, "stock_status", "") == "in stock" ? "imgProduct" : "imgProductOutOfStock"}></img>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={7} xl={6} className="pl-xl-5  py-4 py-md-0 d-flex">
                                        {this.renderContent(quantityArr, sizeOptionsArr, productDetailsData, Ingredients, descriptionContent, vendorArr, speedArr)}
                                    </Col>

                                </Row>
                                {_get(productDetailsData, "related_products", [])?.length > 0 && <Row className="no-gutters justify-content-lg-between secMinHeight">
                                    <div className="proName  mb-3 d-flex align-items-center" >
                                        More products you may like
                                    </div>
                                    <div className="page-content-container">
                                        <TopCategoryComponent
                                            {...this.props}
                                            productsperadd={_get(productDetailsData, "related_products", [])}
                                        />
                                    </div>
                                </Row>}</>}
                    </Container>
                </div>
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    let productDetailsData = _get(state, 'productDetails.lookUpData');
    let categoriesList = _get(state, 'categoriesList.lookUpData.data');
    let userSignInInfo = _get(state, 'userSignInInfo.lookUpData', []);
    let bottleSize = _get(state, 'bottleSize.lookUpData', {});
    let selectedBottleProductId = _get(state, 'bottleID.lookUpData', {});
    return { productDetailsData, categoriesList, userSignInInfo, bottleSize, selectedBottleProductId }
}
export default connect(mapStateToProps)(withStyles(styles)(ProductDetails));