import cleanDeep from 'clean-deep';
import _ from 'lodash';

const getArrayOutOfServerObject = (obj) => {
    let arr = []
    Object.keys(obj).forEach(x => {
        if (x == '0' || Number(x)) {
            arr.push(obj[x]);
        }
    });
    return arr
}

const cleanEntityData = (data) => {
    let cleanData = {};
    try{
        cleanData = cleanDeep(data);
    } catch (e) {
        console.log(e);
    }
    return cleanData;
}

const createReqObjForCart = ()=>{
    let reqObj = {}
    if(localStorage.getItem("Token"))
    reqObj = {
        "api_token": localStorage.getItem("Token"),
        "cart_id":localStorage.getItem("cart_id")
    };
    else{
        reqObj = {
            "cart_id":localStorage.getItem("cart_id")
        };
    }
    return reqObj;
};

const formatPrice = (price) => {
    const newPrice = price.replace(/,/g, '');
    return Number(newPrice);
};

const enrichArrDataToObj = ({ data, field = ''}) => _.reduce(data, (acc, val) => ({ ...acc, [_.get(val, field)]: val }), {});


const deliveryMethods = Object.freeze({
    'Same Day': 'Local Delivery',
    'Next Day': 'Courier Delivery',
    'Farm Direct': 'Farm Direct'
})






export {
    cleanEntityData,
    createReqObjForCart,
    formatPrice,
    enrichArrDataToObj,
    getArrayOutOfServerObject,
    deliveryMethods
}